import React,{Component} from "react";
import './AboutComponent.scss'
import { Paper } from "@material-ui/core";

class AboutComponent extends Component{

    render(){
        return(
            <div className='About' id="About Us">
              <h2> 0Spill Technology </h2>
            <div className="AboutCard">

            
              <div>
                <p>
                  Our unique product provides 360° Spill Proof drinking experience by allowing you to control the flow of liquids with your lips. <br></br>
                  The product accommodates an auto lid-locking feature, that pours water out whenever the pressure is applied either by hand or <br></br>
                  by lips while drinking. The lid automatically closes as soon as the pressure is released.
      
                </p>
              </div>
          
            <div className='AboutImage'>
              <div>
                <img src={require('../../images/20.webp')} />
                {/* <p>Lips Control</p> */}
              </div>
              <div>
                <img src={require('../../images/21.webp')} />
                {/* <p>Finger Control</p> */}
      
              </div>
              <div>
                <img src={require('../../images/22.webp')} />
                {/* <p>360 Sipper Concept</p> */}
      
              </div>
              <div>
                <img src={require('../../images/23.webp')} />
                {/* <p>Easy Pouring </p> */}
              </div>
            </div>
            </div>
          
          </div>
        )
    }
}

export default AboutComponent;