import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Box } from '@mui/material'

// import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import TremblingHands from '../../images/2.webp'
import BumpyRide from '../../images/3.webp'
import OOPSMoments from '../../images/4.webp'
import KidsSpill from '../../images/1.webp'
import './WhyCarousel.css'
const indicator = {
    indicators: {
        width: "100%",
        marginTop: "10px",
        textAlign: "center",
        backgroundColor: 'red'
    },
    indicator: {
        cursor: "pointer",
        transition: "200ms",
        padding: 0,
        color: "#afafaf",
        '&:hover': {
            color: "#1f1f1f"
        },
        '&:active': {
            color: "#ffffff"
        }
    },
    indicatorIcon: {
        fontSize: "15px",
    },
    // Applies to the active indicator
    active: {
        color: "#494949"
    }
}

const navbutton = {
    buttonWrapper: {
        position: "absolute",
        height: "100px",
        backgroundColor: "transparent",
        top: "calc(50% - 70px)",
        '&:hover': {
            '& $button': {
                backgroundColor: "black",
                filter: "brightness(120%)",
                opacity: "0.4"
            }
        }
    },
    fullHeightHoverWrapper: {
        height: "100%",
        top: "0"
    },
    buttonVisible: {
        opacity: "1"
    },
    buttonHidden: {
        opacity: "0",
    },
    button: {
        margin: "0 10px",
        position: "relative",
        backgroundColor: "#494949",
        top: "calc(50% - 20px) !important",
        color: "white",
        fontSize: "30px",
        transition: "200ms",
        cursor: "pointer",
        '&:hover': {
            opacity: "0.6 !important"
        },
    },
    // Applies to the "next" button wrapper
    next: {
        right: 0
    },
    // Applies to the "prev" button wrapper
    prev: {
        left: 0
    }
}


function WhyExample(props) {

    const items = [
        {
            tag1: 'Kids Spilling',
            image1: KidsSpill,
            tag2: 'Trembling Hands',
            image2: TremblingHands
        },
        {
            tag1: 'Bumpy Ride',
            image1: BumpyRide,
            tag2: 'OOPS Moment',
            image2: OOPSMoments
        }
    ]
    return (
        <div className='Why'>
            <div>
                <h2> Why 0Spill ?      </h2>
            </div>
            <Carousel style={{}}
                interval='2000'
                navButtonsAlwaysInvisible='true'
                indicatorContainerProps={{
                    style: {
                        display: 'none'
                    }
                }}
                activeIndicatorIconButtonProps={{
                    style: {
                        // backgroundColor: 'red' // 2
                    }
                }}
                indicatorIconButtonProps={{
                    style: {
                        // padding: '10px',    // 1
                        // color: 'blue',      // 3
                    }
                }}

                navButtonsProps={{
                    style: {
                        backgroundColor: 'blue'
                    }
                }}

            >
                {
                    items.map((item, i) => <Item key={i} item={item} />)
                }
            </Carousel>
        </div>

    )
}

function Item(props) {
    return (

        <Box className='SlideShow'>
            <div className='Images'>
                <div>
                    <img src={props.item.image1} />
                    <p>{props.item.tag1} </p>
                </div>
                <div>
                    <img src={props.item.image2} />
                    <p>{props.item.tag2}</p>
                </div>
            </div>
        </Box>

    )
}





export default WhyExample;

