import React, { useState } from "react";
import "./Quantity.scss";
function Quantity() {
  const [quantity, setQuantity] = useState(1);

  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value);
    setQuantity(newQuantity);
  };

  return (
    <div className="quantity-selector">
      <label>Quantity:</label>
      <input
        style={{ width: "5em" }}
        type="number"
        value={quantity}
        min="1"
        onChange={handleQuantityChange}
      />
    </div>
  );
}

export default Quantity;
