import "./Partners.scss";
import React from "react";
import { useEffect, useState } from "react";

function Partners() {
  const [placementCompanies, setPlacementCompanies] = useState([
    {
      name: "",
      logoUrl: "/images/oston.png",
    },
    {
      name: "",
      logoUrl: "/images/Lupin.png",
    },
    {
      name: "",
      logoUrl: "/images/smarttrak.png",
    },
    {
      name: "",
      logoUrl: "/images/Sun.png",
    },
     {
      name:"",
      logoUrl:"/images/Ajanta.png"
    },
     {
      name:"",
      logoUrl:"/images/SMS.png"
    }
  ]);

  return (
    <div className="partner-section">
      <h2>Our Partners</h2>

      <div className="marquee">
        <ul class="marquee__content">
          {placementCompanies &&
            placementCompanies.length &&
            placementCompanies.map((placementCompany, index) => (
              <li>
                <img
                  key={index}
                  src={placementCompany.logoUrl}
                  alt={`Company ${index}`}
                />
              </li>
            ))}
        </ul>
        <ul class="marquee__content">
          {placementCompanies &&
            placementCompanies.length &&
            placementCompanies.map((placementCompany, index) => (
              <li>
                <img
                  key={index}
                  src={placementCompany.logoUrl}
                  alt={`Company ${index}`}
                />
              </li>
            ))}
        </ul>
        <ul class="marquee__content">
          {placementCompanies &&
            placementCompanies.length &&
            placementCompanies.map((placementCompany, index) => (
              <li>
                <img
                  key={index}
                  src={placementCompany.logoUrl}
                  alt={`Company ${index}`}
                />
              </li>
            ))}
        </ul>
        <ul class="marquee__content">
          {placementCompanies &&
            placementCompanies.length &&
            placementCompanies.map((placementCompany, index) => (
              <li>
                <img
                  key={index}
                  src={placementCompany.logoUrl}
                  alt={`Company ${index}`}
                />
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default Partners;
