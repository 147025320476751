import React, { useEffect, useState } from "react";
import "./TestimonialsCard.scss"






export default function TestimonialCard() {
  const [allReviews, setAllReviews] = useState([
    { "name": "", "imageUrl": "/images/Lupin.png", "position": "Software Engineer", "companyName": "Tiger analytics", "review": "We recently purchased the 0SPILL ALPHA 500 Stainless Steel Vacuum Flasks from 0SPILL and gifted them to our dealers. The feedback has been overwhelmingly positive! Our dealers love the spill-proof technology and the sleek, modern design. The flasks' superior temperature retention ensures their drinks stay hot or cold for hours, making them perfect for long days at work. We've noticed a significant boost in our dealer relationships since presenting these gifts. 0SPILL has truly provided a high-quality, innovative product that stands out in the market. We couldn't be happier with our purchase!" },
     { "name": "", "imageUrl": "/images/Sun.png", "position": "Senior Software Engineer", "companyName": "Cargil", "review": "We recently purchased the OSPILL Stainless Steel coffee mugs to gift to our company staff, and the feedback has been overwhelmingly positive! The innovative spill-proof technology has impressed everyone, and the stylish design is a big hit. The flasks keep beverages hot or cold for hours, making them perfect for the office, gym, or travel. Our team loves the convenience and quality of these flasks. We’re thrilled with our purchase and highly recommend 0SPILL for any business looking to provide their employees with a practical and appreciated gift." }, 
     { "name": "", "imageUrl": "/images/SMS.png", "position": "Data Scientist", "companyName": "Tiger Analytics", "review": "Working with OSPILL has been a game-changer for our business. Their ALPHA 500 Stainless Steel Vacuum Flask, with its innovative lips and finger-controlled spill-proof technology, has been a huge hit with our pharma clients. The product's quality and functionality are unmatched, and it has significantly reduced spillage issues for our customers. The temperature retention feature is perfect for keeping liquids at the desired temperature, making it ideal for professionals on the go. The team at OSPILL is incredibly supportive, and their commitment to excellence is evident in every interaction. We highly recommend OSPILL for their outstanding products and service." },
      { "name": "", "imageUrl": "/images/Ajanta.png", "position": "Software Engineer", "companyName": "Wipro", "review": "We recently purchased the 0SPILL ALPHA 500 Stainless Steel Vacuum Flasks as gifts for our top dealers, and the response has been overwhelmingly positive. The innovative spill-proof technology and sleek design have made these flasks an instant favorite. Our dealers appreciate the high-quality materials and temperature retention, making it perfect for their busy lifestyles. Not only have we strengthened our relationships with our dealers, but we've also aligned ourselves with a product that reflects our commitment to quality and innovation. Thank you, 0SPILL, for helping us make a lasting impression!" }]);
  const [review, setReview] = useState(allReviews[0]);

  // useEffect(() => {
  //   MembersService.getAllTestimonials().then((reviews) => {
  //     setReview(reviews?.[0]);
  //     setAllReviews(reviews);
  //   });
  //   console.log(review);
  // }, []);
  function updateReview(rw) {
    setReview(rw);

  }

  return (
    <div className="TestimonialsCard">


      <div className="testimonials">
        <div className="testimonial-heading">
          <h2 style={{ margin: 0 , color:'white'}}>Testimonials of our Folks</h2>
        </div>

        <div className="feedback-para">
          <p style={{ textAlign: "center", color:'white' }}>
            Candid Insights and Valuable Feedback from Our Esteemed Community
            Members
          </p>
        </div>

        <div className="testimonial-user-data">
          <div className="user-image">
            <img src={review?.imageUrl} alt="userImage" />
            <h4 style={{ margin: 0 }}>{review?.name}</h4>

          </div>

          <div className="user-testimonial-edu">
            <div className="user-testimonial">
              <div className="quotes">
                <img src="/images/quotes.svg" alt="" />
                <img src="/images/quotes.svg" alt="" />
              </div>
              <p style={{ color:'white'}}>{review?.review}</p>
            </div>

            <div className="user-name-edu">
              <div className="user-name">
                {/* <h4 style={{ margin: 0 }}>{review?.name}</h4>
                <p className="user-position-and-company">
                  {review?.position} - {review?.companyName}
                </p> */}
              </div>

              <div className="other-testimonials">
                {allReviews && allReviews.map(rw =>
                  <div onClick={() => updateReview(rw)}
                    className={`other-testimonials-image ${rw.name === review.name ? 'testimonials-image-selected' : ''}`} >
                    <img src={rw.imageUrl} alt="" />
                  </div>)}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
