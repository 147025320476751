import React from 'react';
import './GalleryStrip.css'; // Import the CSS file for styling
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { Paper } from '@mui/material';

const itemData = [
  {
    img: '/instagram/1.webp',
    title: 'Breakfast',
  },
  {
    img: '/instagram/2.webp',
    title: 'Burger',
  },
  {
    img: '/instagram/3.webp',
    title: 'Camera',
  },
  {
    img: '/instagram/4.webp',
    title: 'Coffee',
  },
  {
    img: '/instagram/5.webp',
    title: 'Hats',
  },
  {
    img: '/instagram/6.webp',
    title: 'Honey',
  },
  {
    img: '/instagram/7.webp',
    title: 'Basketball',
  },
  {
    img: '/instagram/8.webp',
    title: 'Fern',
  },
  // {
  //   img: '/instagram/9.webp',
  //   title: 'Mushrooms',
  // }
 ]
const GalleryStrip = ( ) => {
  console.log(window.innerWidth)
  
  return (
    <div className="gallery-strip">
   
      <Paper sx={{paddingTop:'2em'}}>
      <h2>Follow us on <a style={{color:'red'}} href="https://www.instagram.com/ospill__/" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
        </h2>
      
            <ImageList sx={{   padding: '1em' }} cols={window.innerWidth>800 ? 4 : 2} >
            {itemData.map((item) => (
              <ImageListItem key={item.img}>
                <img
                  src={item.img}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
      </ImageList>
      </Paper>
    

    </div>
  );
};

export default GalleryStrip;
