// import { Component } from 'react';
// import './Header.css'
// // import NavBar from './NavBar';

// function Tab(props) {
//   const after_click = () => {
//     document.getElementsByClassName("Tabs").style.color = "black"
//     document.getElementById("nav_" + props.tab.label).style.color = "blue"

//   }

//   return <div >
//     <a onClick={after_click} href={"#" + props.tab.label} id={"nav_" + props.tab.label}>{props.tab.label}</a>
//   </div>;
// }

// class Header extends Component {

//   test(name){
//     return(<div>
//       {"test" + name}
//     </div>)
//   }

//   render() {
//     const tabs_ = [{
//       label: "Home",
//       href: "/Home",
//     }, {
//       label: "Products",
//       href: "/products",
//     }, {
//       label: "About Us",
//       href: "/about",
//     }, {
//       label: "Contact Us",
//       href: "/contact",
//     }];

//     const style = {
//       // width: '12em'

//     }

//     const textfn = (name)=> {
//       return "my name is " + name
//     }
//     return (
//       <div className="Header" id='Header'>
//         {/* <NavBar/> */}
//         <a className='LogoName' href='#Home' >
//           <img style={style} src={require('../images/logo.png')} />
//           <p>Innovative And Futuristic Design</p>
//         </a>
//         {/* {true? this.test("ganesh"): "not test"}
//         {true? textfn("ganesh"): "not test"} */}

//         <div className='Tabs'>
//           {tabs_.map((tab) => <Tab tab={tab} key={tab} />)}
//         </div>
//       </div>
//     );
//   }
// }

// export default Header;

import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import Slide from "@mui/material/Slide";
import "../Header/Header.css";
import { Link } from "react-router-dom";

// function HideOnScroll(props) {
//     const { children, window } = props;
//     // Note that you normally won't need to set the window ref as useScrollTrigger
//     // will default to window.
//     // This is only being set here because the demo is in an iframe.
//     const trigger = useScrollTrigger({
//         target: window ? window() : undefined,
//     });

//     return (
//         <Slide appear={false} direction="down" in={!trigger}>
//             {children}
//         </Slide>
//     );
// }

// HideOnScroll.propTypes = {
//     children: PropTypes.element.isRequired,
//     /**
//      * Injected by the documentation to work in an iframe.
//      * You won't need it on your project.
//      */
//     window: PropTypes.func,
// };
function Tab(props) {
  const after_click = () => {
    // document.getElementById("nav_" + props.tab.label).style.color = "blue"
  };

  return (
    <div className="Tabs">
      <a
        onClick={after_click}
        href={"#" + props.tab.label}
        id={"nav_" + props.tab.label}
      >
        {props.tab.label}
      </a>
    </div>
  );
}

export default function Header(props) {
  const tabs_ = [
    //     {
    //     label: "Home",
    //     href: "/",
    // }
    // , {
    //     label: "Products",
    //     href: "/products",
    // }, {
    //     label: "About Us",
    //     href: "/about",
    // }, {
    //     label: "Contact Us",
    //     href: "/contact",
    // }
  ];
  return (
    <React.Fragment>
      <CssBaseline />
      {/* <HideOnScroll {...props}> */}
      {/* <AppBar sx={{ backgroundColor: "red" }}> */}
      <div className="Header" id="Header">
        <Link className="LogoName" to="/">
          <img src={require("../images/Logo.png")} alt="Logo" />
        </Link>

        <div className="Tabs">
          {tabs_.map((tab) => (
            <Tab tab={tab} key={tab} />
          ))}
        </div>
      </div>
      {/* </AppBar> */}
      {/* </HideOnScroll> */}
    </React.Fragment>
  );
}
